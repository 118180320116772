import { useState } from "react";
import useScrollTo from "../hooks/useScrollTo";

const ClientDeclaration = ({ state, dispatch }) => {
  const [isChecked, setIsChecked] = useState(state.isChecked || false);

  useScrollTo(0, 0, "smooth");

  const onIsCheckedChange = ({ target }) => {
    setIsChecked(!isChecked);
    dispatch({ type: "CHECK_ONE", payload: target.checked });
  };

  return (
    <div className="client-declaration">
      <h2>Client Declaration</h2>
      <p>
        I have decided to use Direct Mediation Services (DMS) to enable me to
        agree arrangements for:
      </p>
      <ul>
        <li>children</li>
        <li>finances</li>
        <li>children and finances</li>
      </ul>
      <ol>
        <li>
          <strong>During mediation I will:</strong>
          <ol type="a">
            <li>show respect and show courtesy to everyone in the room.</li>
            <li>show fairness throughout the mediation process.</li>
            <li>leave fault and criticism out of the mediation discussions.</li>
            <li>co-operate in resolving disagreements.</li>
            <li>
              think about the other party’s needs and the needs of our family as
              a whole.
            </li>
            <li>
              try to reduce the emotional and financial distress caused to our
              family.
            </li>
            <li>
              keep the content of the mediation confidential – this includes all
              forms of social media.
            </li>
            <li>
              I will not record the mediation meeting using a mobile telephone
              or other electronic device.
            </li>
          </ol>
        </li>
        <li>
          <strong>
            If my case is related to Property & Finance, I would voluntarily:
          </strong>
          <ol type="a">
            <li>
              make full and frank disclosure of all joint and individual
              finances and provide all the paperwork which relate to these. I
              understand the financial information provided can be referred to
              in any future court proceedings.
            </li>
            <li>
              not take out any debt, transfer or dispose of any assets, except
              for everyday living expenses, unless the other party and I agree.
            </li>
          </ol>
        </li>
        <li>
          I will not contact the mediator, or talk about issues in mediation
          with the mediator, except during the sessions, unless both of the
          parties agree. I understand a Mediator may however, talk to me or the
          other party separately about the mediation process.
        </li>
        <li>
          I understand all the discussions within mediation with DMS are:
          <ol type="a">
            <li>
              <strong>Confidential.</strong> The exceptions to this are
              information which relates to safeguarding of children and the
              Proceeds of Crime Act 2002.
            </li>
            <li>
              <strong>Privileged.</strong> This means, I cannot refer to any
              discussions or proposals suggested or discussed by either of the
              party's involved in the conflict in in any future court
              proceedings and I cannot call the mediator as a witness.
            </li>
          </ol>
        </li>
        <li>
          I understand that the mediation session summary is the mediator's
          record of the session. It is without prejudice and legally privileged
          and does not record or create a legally binding agreement. You may
          only show this document to your legal advisors (if you have one) in
          connection with obtaining legal advice.
        </li>
        <li>
          I understand if DMS prepares a Memorandum of Understanding (MOU) this
          is not a legally binding agreement until it is formally offered and
          accepted in legal correspondence. The MOU can form the basis of a
          Consent Order or Separation Agreement, which our solicitors will
          prepare.
        </li>
        <li>
          I understand the mediator will end the mediation session if either of
          the parties involved asks, or if the mediator thinks it is
          appropriate.
        </li>
        <li>
          I have been told that it is sensible to consider obtaining independent
          legal advice from solicitors during and after the mediation is
          completed. I understand the mediator can only provide factual and
          legal information, not advice.
        </li>
        <li>
          If I cannot keep a mediation appointment, we will inform the other
          person and Direct Mediation Services, giving at least forty-eight
          hours’ notice. DMS reserve the right to charge for missed
          appointments. <br />
          <strong>I have been informed of and agree to the DMS</strong>{" "}
          <a href="https://www.directmediationservices.co.uk/wp-content/uploads/2020/09/Cancellations-Refund-Policy.pdf">
            Cancellation and Payment Policy
          </a>
          .
        </li>
      </ol>
      <div className="check-box">
        <input
          type="checkbox"
          name="first-term-check"
          id="first-term-check"
          required
          onChange={onIsCheckedChange}
          checked={isChecked}
        />
        <label htmlFor="first-term-check">
          I agree with the mediation terms above
          <span className="color-red">*</span>
        </label>
      </div>
    </div>
  );
};

export default ClientDeclaration;
