import React, { useState } from "react";
import useScrollTo from "../hooks/useScrollTo";
import SelectInput from "../FormComponents/Components/SelectInput";

const privateOrLegalOptions = [
  "Already entitled for Legal Aid",
  "Private",
  "Private but covered under my partner's Legal Aid funding",
];

const OnlineMediation = ({ state, dispatch }) => {
  const [isCheckedOnline, setIsCheckedOnline] = useState(
    state.isCheckedOnline || false
  );
  const [privateOrLegal, setPrivateOrLegal] = useState(
    state.privateOrLegal || "select"
  );

  useScrollTo(0, 0, "smooth");

  const onIsCheckedOnlineChange = ({ target }) => {
    setIsCheckedOnline(!isCheckedOnline);
    dispatch({ type: "CHECK_SECOND", payload: target.checked });
  };
  const onPrivateOrLegalChange = ({ target }) => {
    setPrivateOrLegal(target.value);
    dispatch({ type: "PRIVATE_OR_LEGAL", payload: target.value });
  };

  return (
    <div className="online-mediation">
      <h2>Online Mediation</h2>
      <h4>
        If mediation is being conducted online, I agree to all of the following
        term, as it will protect the confidentiality of the mediation process.
      </h4>
      <ul>
        <li>
          I confirm that I have access to suitable technology/equipment/internet
          and have the ability to use it.
        </li>
        <li>
          I agree that the space from which I attend the online meeting is
          suitable.
        </li>
        <li>I understand the need to be suitably dressed.</li>
        <li>
          I agree, if asked by the mediator, to pan the room with my camera.
        </li>
        <li>I will not audio or video record any mediation session.</li>
        <li>
          I agree that only the participants, and those who have signed the
          Agreement to Mediate, will be present in the online mediation room(s).
        </li>
        <li>I confirm that I am not able to be overheard from my location.</li>
        <li>
          I agree to do all I can to ensure that I am not interrupted during
          online video mediation by anyone else such as children, relatives,
          pets, deliveries.
        </li>
        <li>
          I agree to turn off or put to silent any phones, tablets or computers,
          and disable any alerts announcements or notifications of texts,
          emails, tweets or other social media activity, and close all or any
          other open application.
        </li>
        <li>
          I agree to there being no live or deferred video or audio relay of the
          online mediation to third participants.
        </li>
        <li>
          I agree to allow the mediator to suspend the mediation, if there is
          any remote interruption. The mediator will restart the online session,
          once satisfied that any interruption has been resolved, and that it
          remains appropriate to continue following such interruption.
        </li>
        <li>
          I agree that the mediator may terminate online video mediation if the
          quality of the connection is inadequate, or if there is a breach of
          this agreement.
        </li>
      </ul>
      <div className="check-box">
        <input
          type="checkbox"
          name="second-term-check"
          id="second-term-check"
          required
          onChange={onIsCheckedOnlineChange}
          checked={isCheckedOnline}
        />
        <label htmlFor="second-term-check">
          I agree with the mediation terms above
          <span className="color-red">*</span>
        </label>
      </div>
      <SelectInput
        label="Are you a private client or funded by Legal Aid?"
        options={privateOrLegalOptions}
        optionValue={privateOrLegal}
        onOptionChange={onPrivateOrLegalChange}
        required={true}
      />
    </div>
  );
};

export default OnlineMediation;
