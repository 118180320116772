const Submitted = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <h1
        style={{
          backgroundColor: "white",
          padding: "25px",
          borderRadius: "22px",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        Form Submitted
      </h1>
    </div>
  );
};

export default Submitted;
