import { Route, Routes } from "react-router-dom";
import Form from "../FormComponents/Form";

function App() {
  return (
    <>
      <Routes>
        <Route element={<Form />} path="/" />
        <Route element={<Form />} path="/AGREEMENT_FORM/C1/:id" />
        <Route element={<Form />} path="/AGREEMENT_FORM/C2/:id" />
      </Routes>
    </>
  );
}

export default App;
