import { useState } from "react";
import useScrollTo from "../hooks/useScrollTo";

const ConsentTwo = ({ state, dispatch }) => {
  const [isCheckedPrivate, setIsCheckedPrivate] = useState(
    state.isCheckedPrivate || false
  );

  useScrollTo(0, 0, "smooth");

  const onIsCheckedPrivateChange = ({ target }) => {
    setIsCheckedPrivate(!isCheckedPrivate);
    dispatch({ type: "CHECK_PRIVATE", payload: target.checked });
  };
  return (
    <>
      <h2>CONSENT</h2>
      <h4 className="consent-h-four">
        I have read, understood and accepted Direct Mediation Services’ charging
        and{" "}
        <a href="https://www.directmediationservices.co.uk/costs/">
          payment terms
        </a>{" "}
        for their services and agree their charges as set out below:
      </h4>
      <ul className="consent-list">
        <p>Direct Mediation Services will charge:</p>
        <li>Private Hourly Rate - £130 per person per hour.</li>
        <li>
          Charging for drafting of documents (e.g. Memorandum of
          Understanding/Open Financial Statement/Mediation Summary) will be made
          according to the time taken (£130 per person per hour).
        </li>
        <li>Sessions will be for a minimum of 1 hour.</li>
      </ul>
      <p>
        Direct Mediation Services will prepare an invoice prior to each meeting
        for each private paying client and payment is required in full before
        the meeting. There is no charge for debit cards. Direct Mediation
        Services reserves the right to cancel an appointment if payment has not
        been made in full before the meeting.
      </p>
      <div style={{ marginTop: "2%" }}>
        <input
          type="checkbox"
          name="private-term-check"
          id="private-term-check"
          required
          onChange={onIsCheckedPrivateChange}
          checked={isCheckedPrivate}
        />
        <label htmlFor="private-term-check" style={{ marginLeft: "1%" }}>
          I agree with the mediation terms above
          <span className="color-red">*</span>
        </label>
      </div>
    </>
  );
};

export default ConsentTwo;
