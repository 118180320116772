import { useLocation } from "react-router-dom";
import { onBackHandler, onSubmitHandler } from "../functions/navFunctions";

const FormComponent = ({
  logo,
  currentStepIndex,
  step,
  formStateAgreementToMediate,
  isFirstStep,
  steps,
  isLastStep,
  next,
  goTo,
  back,
  setIsSubmitted,
  id,
}) => {
  const { pathname } = useLocation();
  return (
    <>
      <div className="full-screen">
        <div className="form-screen">
          <div className="yellow-bar"></div>
          <header>
            <img src={logo} alt="Logo" />
          </header>
          <form
            className="form"
            onSubmit={(e) =>
              onSubmitHandler(
                e,
                next,
                currentStepIndex,
                formStateAgreementToMediate,
                goTo,
                steps,
                setIsSubmitted,
                pathname,
                id
              )
            }
          >
            <div className="steps">
              {currentStepIndex + 1} / {steps.length}
            </div>
            {step}
            {formStateAgreementToMediate.iConsent !== "I consent" && (
              <div className="danger">
                <h2>Ok. This makes it a little hard to continue...</h2>
                <p>
                  Because you don't agree to our data protection policy, we we
                  are unable to assist you at this time.
                  <strong>Your application won't be submitted.</strong>
                </p>
              </div>
            )}
            {currentStepIndex === 6 && !formStateAgreementToMediate.url && (
              <p className="color-red">Please click save</p>
            )}
            <div>
              <div className="submit-btn submit">
                {!isFirstStep && (
                  <button
                    type="button"
                    className="back-btn"
                    onClick={(e) =>
                      onBackHandler(
                        e,
                        back,
                        currentStepIndex,
                        formStateAgreementToMediate,
                        goTo
                      )
                    }
                  >
                    Back
                  </button>
                )}
                <button
                  type="submit"
                  className="next-btn"
                  disabled={
                    formStateAgreementToMediate.iConsent !== "I consent" ||
                    (currentStepIndex === 2 &&
                      formStateAgreementToMediate.privateOrLegal ===
                        "select") ||
                    (currentStepIndex === 6 && !formStateAgreementToMediate.url)
                  }
                  style={{
                    filter:
                      (formStateAgreementToMediate.iConsent !== "I consent" ||
                        (currentStepIndex === 2 &&
                          formStateAgreementToMediate.privateOrLegal ===
                            "select") ||
                        (currentStepIndex === 6 &&
                          !formStateAgreementToMediate.url)) &&
                      "opacity(.4)",
                  }}
                >
                  {isLastStep ? "Finish" : "Next"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormComponent;
