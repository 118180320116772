export const reducer = (state, { type, payload }) => {
  // console.log({ state, type, payload });
  switch (type) {
    case "I_CONSENT":
      return { ...state, iConsent: payload };
    case "CHECK_ONE":
      return { ...state, isChecked: payload };
    case "PRIVATE_OR_LEGAL":
      return { ...state, privateOrLegal: payload };
    case "CHECK_SECOND":
      return { ...state, isCheckedOnline: payload };
    case "CHECK_CONSENT":
      return { ...state, isCheckedConsent: payload };
    case "CHECK_PRIVATE":
      return { ...state, isCheckedPrivate: payload };
    case "CHECK_COMPLAINT":
      return { ...state, isCheckedComplaint: payload };
    case "FIRST_NAME":
      return { ...state, firstName: payload };
    case "SURNAME":
      return { ...state, surName: payload };
    case "CONTACT_NUMBER":
      return { ...state, contactNumber: payload };
    case "EMAIL":
      return { ...state, email: payload };
    case "URL":
      return { ...state, url: payload };
    default:
      break;
  }
};
export const defaultState = {
  iConsent: "I consent",
  privateOrLegal: "select",
};
