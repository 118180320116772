import { useState } from "react";
import useScrollTo from "../hooks/useScrollTo";

const Complaints = ({ state, dispatch }) => {
  const [isCheckedComplaint, setIsCheckedComplaint] = useState(
    state.isCheckedComplaint || false
  );

  useScrollTo(0, 0, "smooth");

  const onIsCheckedComplaintChange = ({ target }) => {
    setIsCheckedComplaint(!isCheckedComplaint);
    dispatch({ type: "CHECK_COMPLAINT", payload: target.checked });
  };

  return (
    <>
      <h2
        style={{
          marginBottom: "20px",
        }}
      >
        Complaints
      </h2>
      <p>
        The practice of the mediators at Direct Mediation Services is governed
        by their membership organization and mediation are conducted in
        accordance with the{" "}
        <a href="https://www.familymediationcouncil.org.uk/us/code-practice/">
          Family Mediation Council (FMC) Code of Practice
        </a>
        .
      </p>
      <p>
        Any concern I may have as to the practice of the mediator will be raised
        with them in the first instance. It is the policy of Direct Mediation
        Services to resolve matters internally and informally according to
        Direct Mediation Services’{" "}
        <a href="https://www.directmediationservices.co.uk/wp-content/uploads/2020/09/G1.2-Complaints-Procedure.pdf">
          complaints policy
        </a>
        . If this is not possible, I am aware I can complain in writing to the
        relevant Membership Organization whose complaints procedures are
        available on their websites. I consent to release the mediation file to
        the Membership Organization (and if necessary, the FMC) should I wish to
        escalate a complaint.
      </p>
      <div style={{ marginTop: "2%" }}>
        <input
          type="checkbox"
          name="complaint-term-check"
          id="complaint-term-check"
          required
          onChange={onIsCheckedComplaintChange}
          checked={isCheckedComplaint}
        />
        <label htmlFor="complaint-term-check" style={{ marginLeft: "1%" }}>
          Please confirm your understanding of the terms above
          <span className="color-red">*</span>
        </label>
      </div>
    </>
  );
};

export default Complaints;
