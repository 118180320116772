import { useEffect, useState } from "react";
import SignatureCanvas from "react-signature-canvas";

import useScrollTo from "../hooks/useScrollTo";
import TextInput from "../FormComponents/Components/TextInput";

const MyPersonalDetails = ({ state, dispatch }) => {
  const [firstName, setFirstName] = useState(state.firstName || "");
  const [surName, setSurName] = useState(state.surName || "");
  const [contactNumber, setContactNumber] = useState(state.contactNumber || "");
  const [email, setEmail] = useState(state.email || "");
  const [signature, setSignature] = useState(null);
  const [url, setUrl] = useState(state.url || "");

  useScrollTo(0, 0, "smooth");

  const onFirstNameChange = ({ target }) => {
    setFirstName(target.value);
    dispatch({ type: "FIRST_NAME", payload: target.value });
  };
  const onSurNameChange = ({ target }) => {
    setSurName(target.value);
    dispatch({ type: "SURNAME", payload: target.value });
  };
  const onContactNumberChange = ({ target }) => {
    setContactNumber(target.value);
    dispatch({ type: "CONTACT_NUMBER", payload: target.value });
  };
  const onEmailChange = ({ target }) => {
    setEmail(target.value);
    dispatch({ type: "EMAIL", payload: target.value });
  };
  const onClearHandler = () => {
    signature.clear();
  };
  const onSaveHandler = () => {
    setUrl(signature.getTrimmedCanvas().toDataURL("image/png"));
  };
  useEffect(() => {
    dispatch({ type: "URL", payload: url });
  }, [url, dispatch]);

  return (
    <>
      <h2>My Personal Details</h2>
      <TextInput
        label="First name"
        id="firstName"
        value={firstName}
        onChangeText={onFirstNameChange}
        required={true}
      />
      <TextInput
        label="Surname/Family name"
        id="surname"
        value={surName}
        onChangeText={onSurNameChange}
        required={true}
      />
      <TextInput
        label="Contact number"
        id="contactNumber"
        value={contactNumber}
        onChangeText={onContactNumberChange}
        required={true}
      />
      <div className="form-control colum-direction">
        <label htmlFor="email">
          Email address<span className="color-red">*</span>
        </label>
        <input
          type="email"
          name="email"
          id="email"
          required
          value={email}
          onChange={onEmailChange}
        />
      </div>
      <div>
        <div className="signature">
          <p>Electronic signature</p>
          <SignatureCanvas ref={(data) => setSignature(data)} />
        </div>
        <input type="button" onClick={onClearHandler} value="Clear" />
        <input type="button" onClick={onSaveHandler} value="Save" required />
      </div>
    </>
  );
};

export default MyPersonalDetails;
