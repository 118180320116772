import { client } from "../axios/axios";

export const patchAgreementToMediate = (
  id,
  formStateAgreementToMediate,
  pathname,
  setIsSubmitted
) => {
  const agreementToMediateObject = {
    IConsent: formStateAgreementToMediate.iConsent,
    clientDeclarationAgreed: formStateAgreementToMediate.isChecked,
    onlineMediationAgreed: formStateAgreementToMediate.privateOrLegal,
    privateOrLegal: formStateAgreementToMediate.privateOrLegal,
    complaintsAgreed: formStateAgreementToMediate.isCheckedComplaint,
    personalDetails: {
      firstName: formStateAgreementToMediate.firstName,
      surname: formStateAgreementToMediate.surName,
      contactNumber: formStateAgreementToMediate.contactNumber
        .toString()
        .startsWith("+")
        ? formStateAgreementToMediate.contactNumber
        : "+" + formStateAgreementToMediate.contactNumber,
      emailAddress: formStateAgreementToMediate.email,
      electronicSignature: formStateAgreementToMediate?.url?.split(",")[1],
    },
  };
  if (agreementToMediateObject.privateOrLegal === "Private") {
    agreementToMediateObject.paymentAgreed =
      formStateAgreementToMediate.isCheckedPrivate;
  }
  if (
    agreementToMediateObject.privateOrLegal === "Already entitled for Legal Aid"
  ) {
    agreementToMediateObject.legalAidAgreed =
      formStateAgreementToMediate.isCheckedConsent;
  }

  if (pathname === `/AGREEMENT_FORM/C1/${id}`) {
    client
      .patch(`/addAgreement_C1/${id}`, agreementToMediateObject, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setIsSubmitted(true);
      })
      .catch((err) => console.log({ err: err.message }));
  } else if (pathname === `/AGREEMENT_FORM/C2/${id}`) {
    client
      .patch(`/addAgreement_C2/${id}`, agreementToMediateObject, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setIsSubmitted(true);
      })
      .catch((err) => console.log({ err: err.message }));
  }
};
