import React, { useEffect, useReducer, useRef, useState } from "react";

import { useMultiStepForm } from "../hooks/useMultiStepForm";
import { defaultState, reducer } from "../Logic/reducer";

import logo from "../assets/dms-logotype2.png";
import FormComponent from "./FormComponents";
import FirstPage from "../pages/FirstPage";
import ClientDeclaration from "../pages/ClientDeclaration";
import OnlineMediation from "../pages/OnlineMediation";
import ConsentTwo from "../pages/ConsentTwo";
import Consent from "../pages/Consent";
import MyPersonalDetails from "../pages/MyPersonalDetails";
import Submitted from "../pages/Submitted";
import Complaints from "../pages/Complaints";
import { useParams } from "react-router-dom";

const Form = () => {
  const isMounted = useRef(false);

  const [formStateAgreementToMediate, dispatch] = useReducer(
    reducer,
    JSON.parse(localStorage.getItem("formStateAgreementToMediate")) ||
      defaultState
  );
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    localStorage.setItem(
      "formStateAgreementToMediate",
      JSON.stringify(formStateAgreementToMediate)
    );
  }, [formStateAgreementToMediate]);

  const {
    currentStepIndex,
    steps,
    step,
    isFirstStep,
    isLastStep,
    back,
    next,
    goTo,
  } = useMultiStepForm([
    <FirstPage state={formStateAgreementToMediate} dispatch={dispatch} />,
    <ClientDeclaration
      state={formStateAgreementToMediate}
      dispatch={dispatch}
    />,
    <OnlineMediation state={formStateAgreementToMediate} dispatch={dispatch} />,
    <Consent state={formStateAgreementToMediate} dispatch={dispatch} />,
    <ConsentTwo state={formStateAgreementToMediate} dispatch={dispatch} />,
    <Complaints state={formStateAgreementToMediate} dispatch={dispatch} />,
    <MyPersonalDetails
      state={formStateAgreementToMediate}
      dispatch={dispatch}
    />,
  ]);

  return isSubmitted === true ? (
    <Submitted />
  ) : (
    <FormComponent
      logo={logo}
      currentStepIndex={currentStepIndex}
      formStateAgreementToMediate={formStateAgreementToMediate}
      isFirstStep={isFirstStep}
      isLastStep={isLastStep}
      step={step}
      steps={steps}
      next={next}
      goTo={goTo}
      back={back}
      setIsSubmitted={setIsSubmitted}
      id={id}
    />
  );
};

export default Form;
