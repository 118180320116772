import { patchAgreementToMediate } from "../Logic/patchAgreementToMediate";

export const onBackHandler = (
  e,
  back,
  currentStepIndex,
  formStateAgreementToMediate,
  goTo
) => {
  e.preventDefault();
  back();
  if (currentStepIndex === 4) {
    if (formStateAgreementToMediate.privateOrLegal === "Private") {
      goTo(2);
    } else if (
      formStateAgreementToMediate.privateOrLegal ===
      "Already entitled for Legal Aid"
    ) {
      goTo(3);
    }
  }
  if (currentStepIndex === 5) {
    if (
      formStateAgreementToMediate.privateOrLegal ===
      "Already entitled for Legal Aid"
    ) {
      goTo(3);
    }
    if (formStateAgreementToMediate.privateOrLegal === "Private") {
      goTo(4);
    }
  }
};

export const onSubmitHandler = (
  e,
  next,
  currentStepIndex,
  formStateAgreementToMediate,
  goTo,
  steps,
  setIsSubmitted,
  pathname,
  id
) => {
  e.preventDefault();
  next();
  if (currentStepIndex === 2) {
    if (
      formStateAgreementToMediate.privateOrLegal ===
      "Already entitled for Legal Aid"
    ) {
      goTo(3);
    } else if (formStateAgreementToMediate.privateOrLegal === "Private") {
      goTo(4);
    }
  }
  if (currentStepIndex === 3) {
    if (
      formStateAgreementToMediate.privateOrLegal ===
      "Already entitled for Legal Aid"
    ) {
      goTo(5);
    }
  }

  if (currentStepIndex + 1 === steps.length) {
    patchAgreementToMediate(
      id,
      formStateAgreementToMediate,
      pathname,
      setIsSubmitted
    );
  }
};
