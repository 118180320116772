import { useState } from "react";
import useScrollTo from "../hooks/useScrollTo";

const Consent = ({ state, dispatch }) => {
  const [isCheckedConsent, setIsCheckedConsent] = useState(
    state.isCheckedConsent || false
  );

  useScrollTo(0, 0, "smooth");

  const onIsCheckedConsentChange = ({ target }) => {
    setIsCheckedConsent(!isCheckedConsent);
    dispatch({ type: "CHECK_CONSENT", payload: target.checked });
  };

  return (
    <>
      <h2>CONSENT</h2>
      <p style={{ marginTop: "2%" }}>
        I give my consent to the Legal Aid Agency Auditors seeing our file as
        part of their quality checks. Please note, a refusal to consent will not
        prevent me from providing mediation and will have no bearing on the
        service we offer. If you are eligible for Legal Aid Direct Mediation
        Services’ charges will be met by the Legal Aid Agency.
      </p>
      <div style={{ marginTop: "2%" }}>
        <input
          type="checkbox"
          name="consent-term-check"
          id="consent-term-check"
          required
          onChange={onIsCheckedConsentChange}
          checked={isCheckedConsent}
        />
        <label htmlFor="consent-term-check" style={{ marginLeft: "1%" }}>
          I agree with the mediation terms above
          <span className="color-red">*</span>
        </label>
      </div>
    </>
  );
};

export default Consent;
